<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h4>Prema zakonu o uvođenju EUR tečaj koji se koristi u konverziji je 1 EUR = {{ conversionRateString }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-row style="margin-bottom: 1.7rem">
            <v-spacer></v-spacer>
            <v-col class="calculator-row" cols="2">
              <v-card class="pa-2" outlined tile>
                2 dec
              </v-card>
            </v-col>
            <v-col class="calculator-row" cols="2">
              <v-card class="pa-2" outlined tile>
                4 dec
              </v-card>
            </v-col>
          </v-row>
          <v-row v-for="(row, idx) in 5" :key="idx" style="margin-top: -1.7rem;">
            <v-col class="calculator-row" cols="2">
              <v-card class="pa-2 cards" outlined tile>
                HRK
              </v-card>
            </v-col>
            <v-col class="calculator-row" cols="2">
              <v-text-field :ref="`field${idx}`" outlined dense class="inputs" v-model="leftCalc.hrkValues[row]" @keyup.enter.prevent="onEnter(idx, 'HRK', row)" @keydown.tab.prevent="onEnter(idx, 'HRK', row)"></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="calculator-row" cols="2">
              <v-card class="pa-2 cards" outlined tile>
                EUR
              </v-card>
            </v-col>
            <v-col class="calculator-row" cols="2">
              <v-text-field outlined dense class="inputs" readonly v-model="leftCalc.eurValues2dec[row]" tabindex="-1"></v-text-field>
            </v-col>
            <v-col class="calculator-row" cols="2">
              <v-text-field outlined dense class="inputs" readonly v-model="leftCalc.eurValues4dec[row]" tabindex="-1"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" style="height: 1.7rem"></v-col>
          </v-row>

          <v-row v-for="(row, idx) in 5" :key="`A${idx}`" style="margin-top: -1.7rem;">
            <v-col class="calculator-row" cols="2">
              <v-card class="pa-2 cards" outlined tile>
                EUR
              </v-card>
            </v-col>
            <v-col class="calculator-row" cols="2">
              <v-text-field :ref="`field${idx + 5}`" outlined dense class="inputs" v-model="leftCalc.eurValues[row]" @keyup.enter.prevent="onEnter(idx + 5, 'EUR', row)" @keydown.tab.prevent="onEnter(idx + 5, 'EUR', row)"></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="calculator-row" cols="2">
              <v-card class="pa-2 cards" outlined tile>
                HRK
              </v-card>
            </v-col>
            <v-col class="calculator-row" cols="2">
              <v-text-field outlined dense class="inputs" readonly v-model="leftCalc.hrk2Values[row]" tabindex="-1"></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>

        <!-- RIGHT -->

        <v-col cols="6">
          <v-row style="margin-top: -1.5rem">
            <v-col cols="12">
              <v-card class="pa-2 text-center" style="background-color: bisque" outlined tile>
                Za blagajnu (01.01. - 14.01.2023.)
              </v-card>
            </v-col>
          </v-row>

          <v-row style="margin-top: -1.5rem">
            <v-col cols="4">
              <v-card class="pa-2 cards" outlined tile>
                Iznos računa
              </v-card>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem; margin-left: -0.8rem" cols="2">
              <v-text-field ref="field10" lang='hr' outlined dense autofocus class="inputs" v-model="rightCalc.invoiceAmtEur" @keyup.enter.prevent="onEnter(10)" @keydown.tab.prevent="onEnter(10)"></v-text-field>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem;" cols="2">
              <v-card class="pa-2 cards" outlined tile>
                EUR
              </v-card>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem;" cols="2">
              <v-text-field outlined dense readonly class="inputs" v-model="rightCalc.invoiceAmtHrk" tabindex="-1"></v-text-field>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem;" cols="2">
              <v-card class="pa-2 cards" outlined tile>
                HRK
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" style="height: 2.4rem"></v-col>
          </v-row>
          <v-row style="margin-top: -2.4rem">
            <v-col cols="4">
              <v-card class="pa-2 cards" outlined tile>
                Plaćeno EUR
              </v-card>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem; margin-left: -0.8rem" cols="2">
              <v-text-field ref="field11" outlined dense class="inputs" v-model="rightCalc.paidAmtEur" @keyup.enter.prevent="onEnter(11)" @keydown.tab.prevent="onEnter(11)"></v-text-field>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem;" cols="2">
              <v-card class="pa-2 cards" outlined tile>
                EUR
              </v-card>
            </v-col>
          </v-row>

          <v-row style="margin-top: -2.4rem">
            <v-col cols="4">
              <v-card class="pa-2 cards" outlined tile>
                Plaćeno HRK
              </v-card>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem; margin-left: -0.8rem" cols="2">
              <v-text-field ref="field12" outlined dense class="inputs" v-model="rightCalc.paidAmtHrk" @keyup.enter.prevent="onEnter(12)" @keydown.tab.prevent="onEnter(12)"></v-text-field>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem;" cols="2">
              <v-card class="pa-2 cards" outlined tile>
                HRK
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" style="height: 4.1rem"></v-col>
          </v-row>

          <v-row style="margin-top: -2.4rem;">
            <v-col cols="4">
              <v-card class="pa-2 cards" outlined tile>
                Za vratiti
              </v-card>
            </v-col>
            <v-col class="calculator-row" :class="diff === 0 ? 'back-white' : diff < 0 ? 'back-red' : 'back-green'" style="margin-top: 0.7rem; margin-left: -0.8rem" cols="2">
              <v-text-field outlined dense readonly class="inputs" v-model="rightCalc.changeEur" tabindex="-1"></v-text-field>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem;" cols="2">
              <v-card class="pa-2 cards" outlined tile>
                EUR
              </v-card>
            </v-col>
            <v-col class="calculator-row" :class="diff === 0 ? 'back-white' : diff < 0 ? 'back-red' : 'back-green'" style="margin-top: 0.7rem;" cols="2">
              <v-text-field outlined dense readonly class="inputs" v-model="rightCalc.changeHrk" tabindex="-1"></v-text-field>
            </v-col>
            <v-col class="calculator-row" style="margin-top: 0.7rem;" cols="2">
              <v-card class="pa-2 cards" outlined tile>
                HRK
              </v-card>
            </v-col>
          </v-row>
          <v-row style="margin-top: -1.4rem;">
            <v-col cols="6">
              <v-btn style="margin-right: 1rem" color="primary" depressed @click="clearAll">Obriši sve</v-btn>
              <v-btn style="margin-right: 1rem" color="primary" depressed>Izračunaj</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        conversionRateString: undefined,
        conversionRate: undefined,
        leftCalc: {},
        rightCalc: {},
        invoiceAmtEurValue: 0,
        paidAmtEurValue: 0,
        paidAmtHrkValue: 0,
        changeEur: 0,
        changeHrk: 0,
        diff: 0
      }
    },
    methods: {
      onEnter(idx, currency, row) {
        if (idx === 12) {
            this.convertForRegister('paidAmtHrk')
            idx = -1
        }
        const fieldRef = this.$refs[`field${idx + 1}`]
        if (idx + 1 <= 10) {
          if (fieldRef[0]) { fieldRef[0].focus() } else { fieldRef.focus() }
          this.convert(currency, row)
        } else {
          fieldRef.focus()
          if (idx === 10) {
            this.convertForRegister('invoiceAmtEur')
          } else if (idx === 11) {
            this.convertForRegister('paidAmtEur')
          }
        }
      },
      convert(input, row) {
        if (input === 'HRK') {
          this.leftCalc.hrkValues[row] = this.convertToNumber(this.leftCalc.hrkValues[row])
          if (this.leftCalc.hrkValues[row] !== '') {
            const value = this.leftCalc.hrkValues[row]
            this.leftCalc.hrkValues[row] = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.leftCalc.hrkValues[row])
            this.leftCalc.eurValues2dec[row] = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value / this.conversionRate)
            this.leftCalc.eurValues4dec[row] = new Intl.NumberFormat('hr-HR', { style: "decimal", minimumFractionDigits: 4, maximumFractionDigits: 4 }).format(value / this.conversionRate)
          }
        } else {
          this.leftCalc.eurValues[row] = this.convertToNumber(this.leftCalc.eurValues[row])
          if (this.leftCalc.eurValues[row] !== '') {
            const value = this.leftCalc.eurValues[row]
            this.leftCalc.eurValues[row] = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
            this.leftCalc.hrk2Values[row] = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value * this.conversionRate)
          }
        }
        this.$forceUpdate()
      },
      convertToNumber(fieldValue) {
        if (fieldValue && typeof(fieldValue) === 'string') {
          return parseFloat(fieldValue.replace('.', '').replace(',', '.'))
        }
        return ''
      },
      convertForRegister(target) {
        switch (target) {
          case 'invoiceAmtEur':
            this.rightCalc.invoiceAmtEur = this.convertToNumber(this.rightCalc.invoiceAmtEur)
            if (this.rightCalc.invoiceAmtEur !== '') {
              this.invoiceAmtEurValue = this.rightCalc.invoiceAmtEur
              this.rightCalc.invoiceAmtEur = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.invoiceAmtEurValue)
              this.rightCalc.invoiceAmtHrk = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.invoiceAmtEurValue * this.conversionRate)
            }
            break
          case 'paidAmtEur':
            this.calculateChange()
            break
          case 'paidAmtHrk':
            this.calculateChange()
            break
          default:
            break
        }
        this.$forceUpdate()
      },
      calculateChange () {
        if (this.rightCalc.invoiceAmtEur === '') {
          this.rightCalc.changeEur = ''
          this.rightCalc.changeHrk = ''
          return
        }
        this.totalPaid = 0
        this.rightCalc.paidAmtEur = this.convertToNumber(this.rightCalc.paidAmtEur)
        if (this.rightCalc.invoiceAmtEur !== '' && this.rightCalc.paidAmtEur !== '') {
          this.totalPaid += parseFloat(this.rightCalc.paidAmtEur)
          this.rightCalc.paidAmtEur = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.rightCalc.paidAmtEur)
        }
        this.rightCalc.paidAmtHrk = this.convertToNumber(this.rightCalc.paidAmtHrk)
        if (this.rightCalc.paidAmtHrk !== '') {
          let paidHrkToEur = parseFloat(this.rightCalc.paidAmtHrk / this.conversionRate)
          this.totalPaid += parseFloat(paidHrkToEur)
          this.rightCalc.paidAmtHrk = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.rightCalc.paidAmtHrk)
        }
        this.diff = this.totalPaid - this.invoiceAmtEurValue
        this.changeEur = this.totalPaid - this.invoiceAmtEurValue
        this.changeHrk = this.changeEur * this.conversionRate

        this.rightCalc.changeEur = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.totalPaid - this.invoiceAmtEurValue)
        this.rightCalc.changeHrk = new Intl.NumberFormat('hr-HR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.changeEur * this.conversionRate)  
      },
      clearAll () {
        this.leftCalc.hrkValues = []
        this.leftCalc.eurValues2dec = []
        this.leftCalc.eurValues4dec = []
        this.leftCalc.eurValues = []
        this.leftCalc.hrk2Values = []

        this.rightCalc.invoiceAmtHrk = ''
        this.rightCalc.invoiceAmtEur = ''
        this.rightCalc.paidAmtHrk = ''
        this.rightCalc.paidAmtEur = ''
        this.rightCalc.changeHrk = ''
        this.rightCalc.changeEur = ''
        this.rightCalc.totalPaid = ''
      }
    },
    created () {
      this.conversionRateString = '7.53450'
      this.conversionRate = parseFloat(this.conversionRateString)
      this.leftCalc = 
      {
        hrkValues: [], // HRK => EUR
        eurValues2dec: [], // HRK => EUR
        eurValues4dec: [], // HRK => EUR
        eurValues: [], // EUR => HRK
        hrk2Values: [] // EUR => HRK
      }
      this.rightCalc = 
      {
        invoiceAmtHrk: '',
        invoiceAmtEur: '',
        paidAmtHrk: '',
        paidAmtEur: '',
        changeHrk: '',
        changeEur: '',
        totalPaid: ''
      }
    },
    mounted () {
      const fieldRef = this.$refs.field0
      fieldRef[0].focus()
    }
  }
</script>
<style scoped>
.calculator-row {
  padding: 0;
}
.cards {
  height: 2.5rem;
}
.inputs {
  border-radius: 0;
}
.for-register {
  margin-top: -1.5rem;
}
.back-white {
  height: 39px;
  background-color: rgb(255, 255, 255);
}
.back-red {
  height: 39px;
  background-color: rgb(255, 161, 161);
}
.back-green {
  height: 39px;
  background-color: rgb(179, 233, 179);
}
</style>